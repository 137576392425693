
import { FactcheckSuggestion } from "@/types";
import { Component, Vue, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import * as FactcheckRequestServices from "@/services/FactCheckRequestServices";
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog.vue";
import { useDebounceFn } from '@vueuse/core'
import { formatDistance } from "date-fns";
import { nlBE } from "date-fns/locale";
import { TipTap } from '@/mixins/TipTap';
import { ROOT_ACTIONS } from "@/store/actions";


@Component({
  components: {
    ConfirmationDialog,
    NewFactcheckSuggestionDialog: () => import('@/components/dialogs/NewFactcheckSuggestion.vue'),
  },
})
export default class Index extends mixins(TipTap) {
  tab = 0
  headers = [
    {
      text: "Aanvraag",
      value: "claim",
      cellClass: 'cp',
    },
    {
      text: 'Bron',
      value: 'source',
      cellClass: '',
    },
    {
      text: "Datum",
      value: "date",
      cellClass: 'cp',
    },
    {
      text: "Email",
      value: "email",
      cellClass: 'cp',
    },
    {
      text: "Antwoord",
      value: "answered",
    },
    {
      text: "Doorgegeven",
      value: "passed",
    },
    {
      text: "Verwijderen",
      value: "id",
    },
  ];



  requests: FactcheckSuggestion[] = [];

  selectedRequest: FactcheckSuggestion | null = null;

  response = ``;
  applySignature = true;

  dialogs = {
    details: false,
    sendResponse: false,
    newSuggestion: false,
  };

  loading = {
    sendResponse: false,
  }

  factchecks: Array<{ id: string, title: string }> = [];
  percentageOfRequestsWithFactcheck = null as number | null;
  requestsWithFactcheck = 0;
  async mounted() {
    await this.getRequests();
    const statData = await FactcheckRequestServices.GetPercentageOfRequestWithFactCheck();
    this.percentageOfRequestsWithFactcheck = statData.percentage
    this.requestsWithFactcheck = statData.countWithFactcheck
  }

  async getRequests() {
    this.requests = await FactcheckRequestServices.GetRequests();
  }

  debouncedUpdate = useDebounceFn(async () => {
    await this.updateSelectedRequest();
  }, 500)


  openRequest(request: FactcheckSuggestion) {
    this.selectedRequest = request;
    this.dialogs.details = true;
  }

  async prefillTemplate() {
    let template = localStorage.getItem('requestTemplate') || '';

    template = template.replaceAll('[[naam]]', this.selectedRequest!.fullname)

    this.response = template;
  }

  async deleteRequest(id: string) {
    await FactcheckRequestServices.DeleteRequest(id);
    this.requests = await FactcheckRequestServices.GetRequests();
  }

  async updateSelectedRequest() {
    if (this.selectedRequest == null) return;
    if (this.selectedRequest.FactcheckId && this.selectedRequest.FactcheckId.includes('decheckers.be')) {
      // get the id https://decheckers.be/factchecks/factcheck-krijgen-huishoudhulpen-een-jobbonus-van-450-tot-500-euro-zoals-hilde-crevits-cdv-zegt-in-de-zevende-dag
      this.selectedRequest.FactcheckId = this.selectedRequest.FactcheckId.split('/').pop() || '';
    }
    if (this.selectedRequest.FactcheckId == '') {
      this.selectedRequest.FactcheckId = null;
    }
    await FactcheckRequestServices.UpdateRequest(this.selectedRequest.id, this.selectedRequest!);
    this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
      color: 'info',
      text: 'Aanvraag bijgewerkt'
    })
  }

  openImage() {
    window.open(`https://cdn.decheckers.be/requests/${this.selectedRequest!.id}`)
  }

  formatDate(date: string) {
    return formatDistance(new Date(date), new Date(), { addSuffix: true, locale: nlBE })
  }

  async sendResponse() {

    this.loading.sendResponse = true;
    await FactcheckRequestServices.SendResponse(this.selectedRequest!.id, this.response, this.applySignature);
    this.loading.sendResponse = false;
    this.dialogs.sendResponse = false;
    this.response = '';
    this.requests = await FactcheckRequestServices.GetRequests();
    this.selectedRequest = this.requests.find(r => r.id == this.selectedRequest!.id) || null;
  }

  get uniqueSources() {
    return [...new Set(this.requests.map(r => r.source))];
  }
}
